<template>
    <div id="request-handler" class="p-2">
        <b-loading
            :is-full-page="true"
            v-model="isLoading"
            :can-cancel="false"
        ></b-loading>

        <section class="hero is-medium is-info" v-if="isLoading"> 
            <div class="hero-body">
                <div class="container has-text-centered">
                    <p class="title">
                        Looking up the request... 
                    </p>
                </div>
            </div>
        </section>   

        <section class="hero is-medium is-warning mb-6" v-if="!isLoading && isNotFound"> 
            <div class="hero-body">
                <div class="container has-text-centered">
                    <p class="title">
                        Request not found 
                    </p>
                    <p class="subtitle">
                        Either the request has expired, or it has been 
                        processed, and thus there is nothing to do here....
                    </p>
                </div>
            </div>
        </section>
        <div v-if="!isLoading && isPasswordReset">
            <section class="hero is-small has-background-dark mb-6" > 
                <div class="hero-body">
                    <div class="container has-text-centered">
                        <p class="title has-text-light">
                            Password Reset
                        </p>
                        <p class="subtitle has-text-light">
                            A request was sent to reset your password.  
                        </p>
                    </div>
                </div>
            </section>
            <p class="mb-6">
                When resetting your password, use something you can remember. 
                We require mixed case, as well as at least one number. No 
                shorter than 8 characters, you are welcome to use phrases or 
                sentences, as we do allow spaces, and you have up to 1000 
                characters.  However, we will never be able to reveal your 
                password to you, as the way it is stored, we have no way of
                decrypting it, so if you forget it, you will have to reset it. 
            </p>
                <form action="" @submit="onSubmitPasswordChange">
                    <b-field label="New Password" label-position="on-border">
                        <b-input
                        v-model="password.new"
                        placeholder="Enter your new password"
                        required
                        type="password"
                        autofocus
                        :disabled="disabled"
                        ></b-input>
                    </b-field>
                    <b-field
                        label="Confirm New Password"
                        label-position="on-border"
                    >
                        <b-input
                        v-model="password.confirm"
                        placeholder="Confirm your current password"
                        required
                        type="password"
                        autofocus
                        :disabled="disabled"
                        ></b-input>
                    </b-field>
                    <hr />
                    <div class="level is-mobile">
                    <div class="level-left" />
                    <div class="level-right">
                    <div class="level-item field is-grouped">
                    <button
                    type="sumbit"
                    class="button is-link mr-3"
                    :disabled="disabled"
                    >
                    Change
                    </button>
                    <button
                    type="cancel"
                    class="button is-link is-light"
                    @click="onCancel"
                    :disabled="disabled"
                    >
                    Cancel
                    </button>
                    </div>
                    </div>
                    </div>

                </form>
        </div>
        <div v-if="!isLoading && isVerified">
            <section class="hero is-small has-background-dark mb-6" > 
                <div class="hero-body">
                    <div class="container has-text-centered">
                        <p class="title has-text-light">
                            Account Verification Complete
                        </p>
                        <p class="subtitle has-text-light">
                        </p>
                    </div>
                </div>
            </section>
            <p class="mb-6">
                As a freshly verified account, a password must be set. 
                When setting your password, use something you can remember. 
                We require mixed case, as well as at least one number. No 
                shorter than 8 characters, you are welcome to use phrases or 
                sentences, as we do allow spaces, and you have up to 1000 
                characters.  However, we will never be able to reveal your 
                password to you, as the way it is stored, we have no way of
                decrypting it, so if you forget it, you will have to reset it. 
            </p>
                <form action="" @submit="onSubmitPasswordChange">
                    <b-field label="New Password" label-position="on-border">
                        <b-input
                        v-model="password.new"
                        placeholder="Enter your new password"
                        required
                        type="password"
                        autofocus
                        :disabled="disabled"
                        ></b-input>
                    </b-field>
                    <b-field
                        label="Confirm New Password"
                        label-position="on-border"
                    >
                        <b-input
                        v-model="password.confirm"
                        placeholder="Confirm your current password"
                        required
                        type="password"
                        autofocus
                        :disabled="disabled"
                        ></b-input>
                    </b-field>
                <hr />
                <div class="level is-mobile">
                    <div class="level-left" />
                    <div class="level-right">
                        <div class="level-item field is-grouped">
                            <button
                                type="sumbit"
                                class="button is-link mr-3"
                                :disabled="disabled"
                            >
                                Set Password
                            </button>
                            <button
                                type="cancel"
                                class="button is-link is-light"
                                @click="onCancel"
                                :disabled="disabled"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import API from "@/Druware.Web.Shared/api/api";

    export default {
        name: "request.handler",
        components: {},
        data: function() {
            return {
                isLoading: true,
                isNotFound: false,
                isVerified: false,
                isActive: false,
                isPasswordReset: false,
                disabled: false,
                uid: "",
                session: {},
                request: {},
                password: {
                    current: "",
                    new: "",
                    confirm: ""
                }
            }
        }, 
        methods: {
            getRequest(uid) {                
                API.get(
                    API.url.authentication.registration, 
                    this, uid, function(obj,data,session) {
                    obj.request = data;
                    obj.$attrs.session = session;
                    obj.isNotFound = (data.includes("No Request Available"));
                    obj.isVerified = (data.includes("Verified"));
                    obj.isPasswordReset = (data.includes("Pending Password Reset"));
                    obj.isLoading = false;
                }, function(obj,err) {
                    obj.showMessage("Error Reading Session: " + err);
                });
            },
            newUserRequest() {

            },
            onSubmitPasswordChange(e) {
                e.preventDefault();
                this.disabled = true;
                API.put(
                    API.url.authentication.registration, 
                    this,
                    this.uid,
                    this.password,
                    function(obj, data, session) {
                        obj.session = session;
                        obj.showMessage(data, "is-success");
                        obj.disabled = false;
                        obj.$router.push("/");
                    },
                    function(obj, err) {
                        obj.disabled = false;
                        obj.showErrorMessage("Server returned an error: " + err);
                    }
                );
            },
            showMessage(message, type = "is-info") {
                this.$buefy.toast.open({
                duration: 5000,
                message: message,
                position: "is-bottom",
                type: type
        });
            }
        },
        created() {
            this.uid = this.$route.params.uid;
            this.session = this.$attrs.session;            
            this.getRequest(this.uid);
        }
    }
</script>

<style scoped lang="scss">
    #request-handler {
        .loading {
            position: relative;
        }

    }
</style>