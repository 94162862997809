<template>
  <div id="view-profile">
    <b-loading
      :is-full-page="false"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>
    <b-card title="Profile" :centered="true">
      <div class="media left">
        <div class="media-left is-hidden-touch">
          <figure class="image is-48x48">
            <img src="icon/user_48x48.png" alt="Login Icon" />
          </figure>
        </div>
        <div class="media-content">
          <b-tabs
            v-model="activeTab"
            size="is-small"
            type="is-boxed"
            position="is-centered"
          >
            <b-tab-item label="Me">
              <form action="" @submit="updateUser">
                <div class="content">
                  <p>
                    The data you keep here is what the system will use to
                    identify however, the only publicly displayed information is
                    generally just first and last name.
                  </p>
                  <b-field label="Last Name" label-position="on-border">
                    <b-input
                      ref="login-lastName"
                      v-model="login.lastName"
                      placeholder="Enter your last name"
                      required
                      autofocus
                      :disabled="disabled"
                    ></b-input>
                  </b-field>
                  <b-field label="First Name" label-position="on-border">
                    <b-input
                      v-model="login.firstName"
                      placeholder="Enter your first name"
                      :disabled="disabled"
                    ></b-input>
                  </b-field>
                  <b-field label="Middle Name" label-position="on-border">
                    <b-input
                      v-model="login.middleName"
                      placeholder="Enter your middle name"
                      :disabled="disabled"
                    ></b-input>
                  </b-field>
                  <hr />
                  <b-field label="e-mail" label-position="on-border">
                    <b-input
                      v-model="login.email"
                      type="email"
                      placeholder="Enter your email"
                      :disabled="disabled"
                    ></b-input>
                  </b-field>
                  <b-field label="Phone" label-position="on-border">
                    <b-input
                      v-model="login.phone"
                      type="tel"
                      placeholder="Enter your phone"
                      :disabled="disabled"
                    ></b-input>
                  </b-field>
                </div>
                <div class="level is-mobile">
                  <div class="level-left">
                    <small v-if="error != ''" class="level-item is-danger">
                      {{ error }}
                    </small>
                  </div>
                  <div class="level-right">
                    <div class="field is-grouped">
                      <button
                        type="sumbit"
                        class="button is-link"
                        :disabled="disabled"
                      >
                        Save Changes
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </b-tab-item>

            <b-tab-item label="Password">
              <form action="" @submit="onChangePassword">
                <div class="content">
                  <p>
                    You can change your password here. You must provide your
                    current password before you can set a new one.
                  </p>
                  <b-field label="Old Password" label-position="on-border">
                    <b-input
                      v-model="password.current"
                      placeholder="Enter your current password"
                      required
                      type="password"
                      autofocus
                      :disabled="disabled"
                    ></b-input>
                  </b-field>
                  <hr />
                  <b-field label="New Password" label-position="on-border">
                    <b-input
                      v-model="password.new"
                      placeholder="Enter your new password"
                      required
                      type="password"
                      autofocus
                      :disabled="disabled"
                    ></b-input>
                  </b-field>
                  <b-field
                    label="Confirm New Password"
                    label-position="on-border"
                  >
                    <b-input
                      v-model="password.confirm"
                      placeholder="Confirm your current password"
                      required
                      type="password"
                      autofocus
                      :disabled="disabled"
                    ></b-input>
                  </b-field>
                </div>
                <div class="level is-mobile">
                  <div class="level-left">
                    <small v-if="error != ''" class="level-item is-danger">
                      {{ error }}
                    </small>
                  </div>
                  <div class="level-right">
                    <div class="field is-grouped">
                      <button
                        type="sumbit"
                        class="button is-link"
                        :disabled="disabled"
                      >
                        Change Password
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </b-tab-item>

            <!--b-tab-item label="Contacts">
              <li v-for="c in contacts" :key="c.partyId">
                {{ c.lastName }}, {{ c.firstName }}
              </li>
            </b-tab-item-->
          </b-tabs>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import API from "@/Druware.Web.Shared/api/api";
import BCard from "@/Druware.Web.Shared/controls/Card";

const LOGINMODEL = {"loginId":null,"userName":null,"statusId":null,"firstName":null,"lastName":null,"middleName":null,"email":null,"phone":null,"status":null,};

export default {
  name: "view-profile",
  components: {
    BCard
  },
  data() {
    return {
      session: {},
      login: LOGINMODEL,
      contact: {},
      password: {
        current: "",
        new: "",
        confirm: ""
      },
      error: "",
      disabled: false,
      activeTab: undefined,
      isLoading: true,
      contacts: []
    };
  },
  methods: {
    updateUser(e) {
      e.preventDefault();
      this.disabled = true;
      API.put(
        API.url.authentication.user,
        this,
        this.login.loginId,
        this.login,
        function(obj, data) {
          obj.login = data;
          obj.disabled = false;
          obj.isLoading = false;
          obj.showMessage("Changes Saved!.", "is-success");
          obj.$emit("onComplete");
        },
        function(obj, err) {
          obj.disabled = false;
          obj.showMessage("Server returned an error: " + err);
        }
      );
    },
    onChangePassword(e) {
      e.preventDefault();
      this.disabled = true;
      
      API.post(
        API.url.authentication.user + this.login.loginId,
        this,
        this.password,
        function(obj) {
          obj.disabled = false;
          obj.password = { current: "", new: "", confirm: "" };
          obj.showMessage("Changes Saved!.", "is-success");
        },
        function(obj, err) {
          obj.disabled = false;
          obj.showMessage("Server returned an error: " + err);
        }
      );
    },
    onCancel() {
      this.$router.back();
    },
    showMessage(msg, type = "is-danger") {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,
        position: "is-bottom",
        type: type
      });
    }
  },
  watch: {
    login(to, from) {
      if (to != from) {
        this.login = to;
        this.isLoading = false;
        return;
      }
      if (this.login == null) {
        this.login = LOGINMODEL;
      }
    },
    session(to, from) {
      if (to != from) {
        this.login = to.login;
        return;
      }
      if (this.login == null) {
        this.login = LOGINMODEL;
      }
    }
  },
  mounted() {
    this.session = this.$attrs.session;
  }
};
</script>
