<template>
  <div id="view-forgotten">
    <b-card title="Forgotten Password" :centered="true">
      <div class="media left">
        <div class="media-left is-hidden-touch">
          <figure class="image is-48x48">
            <img src="icon/ulock_48x48.png" alt="Security Icon" />
          </figure>
        </div>
        <div class="media-content content">
          <form action="" @submit="onSubmit">
            <p class="mb-6">
              If you have forgotten your password, enter your email address 
              here, and we will send you an email that will allow you to 
              change that password to something new.  Something secure.  
              Something you can remember. If you cannot remember it, well, 
              you can always reset it the next time you forget too.
            </p>
            <b-field label="e-mail" label-position="on-border">
              <b-input
                ref="login-email"
                v-model="email"
                placeholder="Enter your e-mail address"
                required
                autofocus
                :disabled="disabled"
              ></b-input>
            </b-field>
            <hr />
            <div class="level is-mobile">
              <div class="level-left" />
              <div class="level-right">
                <div class="level-item field is-grouped">
                  <button
                    type="sumbit"
                    class="button is-link mr-3"
                    :disabled="disabled"
                  >
                    Request
                  </button>
                  <button
                    type="cancel"
                    class="button is-link is-light"
                    @click="onCancel"
                    :disabled="disabled"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import API from "@/Druware.Web.Shared/api/api";
import BCard from "@/Druware.Web.Shared/controls/Card";

export default {
  name: "view-forgotten",
  components: {
    BCard
  },
  data() {
    return {
      session: null,
      email: "",
      disabled: false
    };
  },
  methods: {
    onSubmit(e) {
      e.preventDefault();
      this.disabled = true;
      API.post(
        API.url.authentication.registration + this.email, 
        this,
        this.email,
        function(obj, data, session) {
          obj.session = session;
          obj.$emit("onComplete", obj.session);
          obj.$buefy.toast.open({
            duration: 5000,
            message: data,
            position: "is-bottom",
            type: "is-success"
          });
          obj.disabled = false;
          obj.$router.back();
        },
        function(obj, err) {
          obj.disabled = false;
          obj.showErrorMessage("Server returned an error: " + err);
        }
      );
    },
    onCancel() {
      this.$router.back();
    },
    showErrorMessage(msg) {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,
        position: "is-bottom",
        type: "is-danger"
      });
    }
  },
  mounted() {
    this.session_internal = this.session;
    this.login = { userName: "", password: "", uid: "" };
    this.disabled = false;
  }
};
</script>
